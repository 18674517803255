<template>
  <div>
    <span @click="showDialog = true">

      <v-btn
        icon
        small
      >
        <v-icon size="18">
          {{ icons.mdiInformationOutline }}
        </v-icon>
      </v-btn>
    </span>

    <v-dialog
      v-model="showDialog"
      width="600px"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialog = false">
            <v-icon size="18">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="showDialog = false"
          >
            Schließen
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiInformationOutline } from '@mdi/js'

export default {
  name: "InfoPopup",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const showDialog = ref(false);

    return {
      showDialog,
      icons: {
        mdiClose,
        mdiInformationOutline
      }
    }
  }
};
</script>
